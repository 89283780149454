import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Calendar from '../components/calendar';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Eventos`}</h2>
    <Calendar mdxType="Calendar"></Calendar>
    {
      /*
      <ProjectCard
       title="#"
       link="#"
       bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
      >
       Lorem ipsum.
      </ProjectCard>
      <ProjectCard
       title="#"
       link="#"
       bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      >
       Lorem ipsum.
      </ProjectCard>
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      