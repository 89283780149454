/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics";

type ButtonEventProps = {
  link?: string
  style?: string
  eventCategory: string
  eventAction: string
  eventLabel: string
  children: React.ReactNode
}

const ButtonEvent = ({ link = `#`, style, eventCategory, eventAction, eventLabel, children }: ButtonEventProps) => {
  let registerEvent = () => {
    trackCustomEvent({
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    });
  }

  return (
  <div sx={style}>
    <OutboundLink 
    href={link}
    target="_blank"
    onClick={registerEvent}
    sx={{ 
      color: `#fff`,
      borderRadius: `lg`,
      border: `3px solid #fec10d`,
      bg: `#ef3e22a0`,
      py: `1`,
      px: `2` }}
    eventCategory={eventCategory}
    eventAction={eventAction}
    eventLabel={eventLabel}
    >
      {children}
    </OutboundLink>
  </div>
  )
}

export default ButtonEvent