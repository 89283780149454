/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import ButtonEvent from "./button-event";
import ProjectCard from "./project-card";
import { OutboundLink } from "gatsby-plugin-google-analytics";

class Calendar extends React.Component<{}, { loaded: boolean, data: Array<Object>, msg: string }> {

  constructor(props) {
      super(props);
      this.state = {
          loaded: false,
          data: null,
          msg: ""
      };
  }

  componentDidMount() {
    let calendarId = "alexiamonacoteixeira@gmail.com";
    let apikey = "AIzaSyC3iVzfs7IMI1l348LvgvMZ-UQ61flZUfs";
    let orderBy = "startTime"; // or "updated"
    let maxResults = 4;
    let noEventsMsg = "sem eventos no momento";

    let endpoint = "https://www.googleapis.com/calendar/v3/calendars/"+calendarId+"/events?key="+apikey+"&maxResults="+maxResults+"&orderBy="+orderBy+"&timeMin="+(new Date).toJSON()+"&singleEvents=true";
    
    fetch(endpoint).then( data => {
      return data.json();          
    }).then(data => {
        let events = [];
        if (data == null || data == ""){
          this.setState({
            loaded: false,
            data: null,
            msg: noEventsMsg
          });
        }
        events = data.items;
          /* what properties come bundled with each event:
            created: "2021-03-11T02:13:52.000Z"
            creator: {email: "alexiamonacoteixeira@gmail.com", self: tru…}
              email: "alexiamonacoteixeira@gmail.com"
              self: true
            description: "vai rolar bundalele"
            end: {dateTime: "2021-03-29T09:30:00-03:00", timeZone: "…}
              dateTime: "2021-03-29T09:30:00-03:00"
              timeZone: "America/Sao_Paulo"
            etag: "\"3232702741478000\""
            eventType: "default"
            htmlLink: "https://www.google.com/calendar/event?eid=M2hpZ2xzdWQwcGgxNmRwbTk1dmszZG5mNDZfMjAyMTAzMjlUMTEzMDAwWiBhbGV4aWFtb25hY290ZWl4ZWlyYUBt"
            iCalUID: "3higlsud0ph16dpm95vk3dnf46@google.com"
            id: "3higlsud0ph16dpm95vk3dnf46_20210329T113000Z"
            kind: "calendar#event"
            location: "Méier, Rio de Janeiro - RJ, Brasil"
            organizer: {email: "alexiamonacoteixeira@gmail.com", self: tru…}
              email: "alexiamonacoteixeira@gmail.com"
              self: true
            originalStartTime: {dateTime: "2021-03-29T08:30:00-03:00", timeZone: "…}
              dateTime: "2021-03-29T08:30:00-03:00"
              timeZone: "America/Sao_Paulo"
            recurringEventId: "3higlsud0ph16dpm95vk3dnf46"
            sequence: 3
            start: {dateTime: "2021-03-29T08:30:00-03:00", timeZone: "…}
              dateTime: "2021-03-29T08:30:00-03:00"
              timeZone: "America/Sao_Paulo"
            status: "confirmed"
            summary: "o que eu mandar todo mundo vai fazer"
            updated: "2021-03-21T18:29:30.739Z"
          */

        for (let event of events){
          event.htmlLink = this.generateEventLinkFromGCal(event);
        }
        this.setState({
            loaded: true,
            data: events,
            msg: ""
        });
    });
  }

  generateEventLinkFromGCal(event: object){
    /* How the "href" attribute is being setted: if there's one or more URLs within the "event.description" text, apply the last one to the href attribute. Otherwise, set it with the GCalendar Event's URL
    
    RegEx that finds all URL occurrences: (?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])

    How to find the last occurrence: simply put the regex above plus "(?!.*plusTheRegexAbove" ((?!.*something) -> this feature is called Negative Lookahead) plus the regex above.
    */
    let result = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])(?!.*(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$]))/igm.exec((event.description+'').trim());
    if (result) {
      return result[0];
    } else {
      // return event.htmlLink;

      /* reference about how the lines below were built: https://stackoverflow.com/a/19867654 && https://zaclee.net/php/formatting-google-calendar-links-with-timezones-and-php-carbon */

      //let endpoint = "https://calendar.google.com/calendar/render?action=TEMPLATE&text=";
      // OR (it works too):
      let endpoint = "https://calendar.google.com/calendar/r/eventedit?text=";
      return endpoint + `${encodeURIComponent(event.summary)}&dates=${new Date(event.start.dateTime).toISOString().replace(".", "").replace(/:/g, "").replace(/-/g, "")}/${new Date(event.end.dateTime).toISOString().replace(".", "").replace(/:/g, "").replace(/-/g, "")}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(event.location)}&ctz=America/Sao_Paulo`;
    }
  }

  render() {
      const parseDate = (dstr: string) => {
        let day, hour, m, min, mon, offset, ret, sec, year;
        if (m = dstr.match(/^(\d{4})-(\d{2})-(\d{2})$/)) {
            return new Date(parseInt(m[1], 10), parseInt(m[2], 10) - 1, parseInt(m[3], 10), 0, 0, 0)
        }
        offset = (new Date).getTimezoneOffset() * 60 * 1e3;
        year = mon = day = null;
        hour = min = sec = 0;
        if (m = dstr.match(/^(\d{4})-(\d{2})-(\d{2})[T ](\d{2}):(\d{2}):(\d{2}(?:\.\d+)?)(Z|([+-])(\d{2}):(\d{2}))$/)) {
            year = parseInt(m[1], 10);
            mon = parseInt(m[2], 10);
            day = parseInt(m[3], 10);
            hour = parseInt(m[4], 10);
            min = parseInt(m[5], 10);
            sec = parseInt(m[6], 10);
            offset = new Date(year, mon - 1, day, hour, min, sec).getTimezoneOffset() * 60 * 1e3;
            if (m[7] !== "Z") {
                offset += (m[8] === "+" ? 1 : -1) * (parseInt(m[9], 10) * 60 + parseInt(m[10], 10)) * 1e3 * 60
            }
        } else {
            console.warn("Time parse error! Unknown time pattern: " + dstr);
            return new Date(1970, 1, 1, 0, 0, 0)
        }
        // console.debug("time parse (gap to local): " + offset);
        ret = new Date(new Date(year, mon - 1, day, hour, min, sec).getTime() - offset);
        // console.debug("time parse: " + dstr + " -> ", ret);
        return ret
      };

      const padZero = (n) => { return n < 10 ? "0"+n : n; };

      const simpleDateFormatter = function (date: Date) {
        return padZero(date.getDate()) + "/" + padZero(date.getMonth()+1) + "/" + date.getFullYear().toString();
      };

      // const dateAndTimeFormatted = function (start_date: Date, end_date: Date) {
      //   return simpleDateFormatter(start_date) +
      //   " - " + padZero(start_date.getHours()) + ":" + padZero(start_date.getMinutes()) +
      //   " até " + padZero(end_date.getHours()) + ":" + padZero(end_date.getMinutes());
      // };

      const timeFormatter = function (start_date: Date, end_date: Date) {
        return padZero(start_date.getHours()) + ":" + padZero(start_date.getMinutes()) +
        " até " + padZero(end_date.getHours()) + ":" + padZero(end_date.getMinutes());
      };

      if ( !this.state.loaded ) {
          return (
            <ProjectCard
              title="Eventos"
              link=""
              bg="#4b3366"
            >
              <div className="events-calendar">
                <strong>
                  {this.state.msg}, mas fique ligado no <OutboundLink href="https://www.instagram.com/alexia.monaco/" target="_blank" eventCategory="Link" eventAction="sem eventos, clicou no link do Instagram" eventLabel="Eventos">Instagram!</OutboundLink>
                </strong>
              </div>
            </ProjectCard>
          )
      }

      return (
        this.state.data.map( (event, index) => (
          <React.Fragment key={index}>
              <ProjectCard
                title={event.summary}
                link=""
                bg="#4b3366"
                txtTransform="none"
              >
                  <div className="events-calendar" sx={{textAlign: 'center'}}>
                    {/* How the "href" attribute is being setted: if there's one or more URLs within the "event.description" text, apply the last one to the href attribute. Otherwise, set it with the GCalendar Event's URL */}
                    <OutboundLink 
                    href={event.htmlLink}
                    target="_blank"
                    sx={{
                        display: `block`,
                        color: `#fff`,
                        borderRadius: `lg`,
                        py: `1`,
                        fontSize: [`2`, `2`, `2`, `2`]
                      }}
                    eventCategory="Link"
                    eventAction={"clicou no link " + event.htmlLink + " do evento " + event.summary}
                    eventLabel="Eventos"
                    >
                      
                      <div>{simpleDateFormatter(parseDate(event.start.dateTime))}</div>

                      <span sx={{ display: `block`, py: `1`, mb: `1` }}>
                        {timeFormatter(parseDate(event.start.dateTime), parseDate(event.end.dateTime))}
                      </span>
                    </OutboundLink>
                    <ButtonEvent link={event.htmlLink} eventCategory="Botão" eventAction={"clicou no botão 'participar' do evento " + event.summary + " (" + event.htmlLink + ")"} eventLabel="Eventos">
                      participar
                    </ButtonEvent>
                  </div>
              </ProjectCard>
          </React.Fragment>
        ))
      );
  }
}

export default Calendar