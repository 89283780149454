import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Modal from '../components/modal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Aulas`}</h2>
    <ProjectCard title="Aula de FitDance Online" bg="#4b3366" mdxType="ProjectCard">
  Quer aprender as coreografias mais atuais da FitDance no conforto da sua casa? Acha que fazer isso sozinha é muito desafiador?<br /><br />
  
  Então é agora que vou te ajudar a simplificar isso.
  <Modal link="whatsapp" style={{
        "mt": "3"
      }} buttonText="quero assistir!" modalTitle="Opa, bora dançar" modalCta="Enviar" mdxType="Modal">
    Mas antes, me passa seu email pra gente bater um papo :)
  </Modal>
  
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      