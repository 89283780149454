/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"

type IntroProps = {
  title: string
  msg: string
}

const Intro = ({ title, msg }: IntroProps) => (
  <div>
    <h1 sx={{ color: `#fec10d`, fontSize: [6, 7, 8] }}>{title}</h1>

    <p>{msg}</p>
  </div>
)

export default Intro