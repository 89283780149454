/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import MyParallaxContext from "../hooks/my-parallax-context";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

type ButtonAnchorProps = {
  page?: number
  style?: string
  children: React.ReactNode
}

const ButtonAnchor = ({ page = 1, style, children }: ButtonAnchorProps) => {
  const context = React.useContext(MyParallaxContext);

  let scrollAndRegisterEvent = () => {
    context.ref.current.scrollTo(page);

    trackCustomEvent({
      category: "Botão",
      action: "Clicou no botão de 'saiba mais'",
      label: "Botão Saiba Mais",
    });
  }

  return (
    <div sx={style}>
      <button 
        type="button"
        sx={{ fontSize: [ 2, 2 ], color: `#FFF`, borderRadius: `lg`, border: `3px solid #FEC10D`, bg: `#EF3E22A0`, py: `1`, px: `2`, cursor: `pointer`, transition: `background-color 0.1s linear` }}
        onClick={scrollAndRegisterEvent}
        onMouseOver={ element => element.target.style.color='#F6AD55'}
        onMouseOut={ element => element.target.style.color='#FFF'}
      >
        {children}
      </button>
    </div>
  )
}

export default ButtonAnchor