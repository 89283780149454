import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Intro from '../components/intro';
import ButtonAnchor from "../components/button-anchor";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Intro title="Oi, eu sou a Alexia" msg="Eu ensino você a viver mais através da dança e exercícios com muita diversão!" mdxType="Intro">
    </Intro>
    <ButtonAnchor page="1" style={{
      "textAlign": "center"
    }} mdxType="ButtonAnchor">
saiba mais
&#9660;
    </ButtonAnchor>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      