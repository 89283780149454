import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 style={{
      textShadow: '2px 3px #4b3366'
    }}>Sobre mim</h2>
    <blockquote>
    <p style={{
        color: '#ef3e22',
        textShadow: '1px 1px 0px #00000080'
      }}>Uma deusa, uma louca, uma feiticeiraaaa...</p>
    </blockquote>
    <p style={{
      color: '#4b3366',
      textShadow: '0px 1px 0px #ffffff80'
    }}>Sou brincalhona! O humor é uma coisa que me cativa MUITO!</p>
    <p style={{
      color: '#4b3366',
      textShadow: '0px 1px 0px #ffffff80'
    }}>Funciona como uma espécie de cura especial, que consegue fazer com que a gente sintonize com energias mais leves.</p>
    <p style={{
      color: '#4b3366',
      textShadow: '0px 1px 0px #ffffff80'
    }}>Parece que esvazia um pouco o nosso potinho de preocupações... aumenta o quentinho do coração e faz com que a gente se reconecte com a nossa essência.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      