/** @jsx jsx */
import React, { useState } from "react";
import { jsx } from "theme-ui"
import { useTransition } from "react-spring";
import { createPortal } from "react-dom";
import ModalForm from "./modalform";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

type ModalProps = {
    link: string
    buttonText: string
    modalTitle: string
    modalCta: string
    style?: string
    children: React.ReactNode
}

export default function Modal({ link, buttonText, modalTitle, modalCta = `Enviar`, style, children }: ModalProps) {
  const [showModal, setShowModal] = useState(false);
  const transitions = useTransition(showModal, null, {
    from: { opacity: 0, transform: "translateY(-200px)" },
    enter: { opacity: 0.93, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-200px)" }
  });
  return (
    <>
        <div sx={style}>
            <a sx={{ color: `#fff`, borderRadius: `lg`, border: `3px solid #fec10d`, bg: `#ef3e22a0`, py: `1`, px: `2`, cursor: `pointer`, fontSize: `1`, transition: `background-color 0.1s linear` }}
                type="button"
                // style={{ transition: "all .15s ease" }}
                onClick={() => {
                  setShowModal(true);

                  trackCustomEvent({
                    category: "Botão",
                    action: "Clicou e abriu o Modal da Aula de FitDance Online",
                    label: "Aula de FitDance Online",
                  });
                }}
            >
                {buttonText}
            </a>
            {createPortal(
              transitions.map(
                  ({ item, key, props: animation }) =>
                  item && (
                      <ModalForm
                      style={animation}
                      closeModal={() => setShowModal(false)}
                      key={key}
                      link={link}
                      modalText={children}
                      modalTitle={modalTitle}
                      modalCta={modalCta}
                      />
                  )
              )
              ,
              document.body
            )
            }
        </div>
    </>
  );
}