/** @jsx jsx */
import { useState } from "react";
import { jsx } from "theme-ui";
import { animated } from "react-spring";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import "./modal.css";

const ModalForm = ({ style, closeModal, link, modalTitle, modalText, modalCta }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  let closeModalAndRegisterEvents = () => {
    trackCustomEvent({
      category: "Botão",
      action: "Clicou no botão de Fechar Modal da Aula de FitDance Online",
      label: "Aula de FitDance Online",
    });

    let fields = [{nome: name}, {email: email}, {whatsapp: whatsapp}];
    let filledFields = [];
    for (let index in fields) {
      if ( Object.values(fields[index])
          .some(fieldValue => (fieldValue !== null && fieldValue !== '')) ){
        filledFields.push( Object.keys(fields[index])[0] );
      }
    }
    if (filledFields.length) {
      trackCustomEvent({
        category: "Formulário",
        action: "Desistiu de enviar dados, mas preencheu os campos: " + filledFields.toString(),
        label: "Aula de FitDance Online",
      });
    }

    closeModal();
  }

  let handleChange = (e) => {
    if (e.target.name === 'name'){
      setName(e.target.value);
    }
    if (e.target.name === 'email'){
      setEmail(e.target.value);
    }
    if (e.target.name === 'whatsapp'){
      setWhatsapp(e.target.value);
    }
  }

  let handleSubmit = async () => {
    let errorDiv = document.getElementById("error");
    let regexValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let invalid = false;
    let regexValidBrazilianPhone = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/; // telefone opcionalmente com "+00 " ou "+55 " e obrigatoriamente com DDD
    
    if (!name || !email || !whatsapp) {
      errorDiv.innerText = "preencha os campos acima!";
      invalid = true;
    } else if (!regexValidEmail.test(email)) {
        errorDiv.innerText = "email inválido!";
        invalid = true;
    } else if (name.length < 3 || name.length > 100) {
        errorDiv.innerText = "nome deve ter de 3 a no máximo 100 letras!";
        invalid = true;
    } else if (email.length > 255){
        errorDiv.innerText = "email deve ter menos de 255 letras!";
        invalid = true;
    } else if (!regexValidBrazilianPhone.test(whatsapp)){
      errorDiv.innerText = "número de whatsapp inválido!";
      invalid = true;
    } else {
      errorDiv.classList.remove("error");
      errorDiv.style.display = "none";

      let formData = {
        nome: name,
        email: email,
        whatsapp: whatsapp
      };

      // track conversions (success clicks) to Google Analytics
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: "Botão",
        // string - required - Type of interaction (e.g. 'play')
        action: "Clicou com sucesso no botão de 'enviar' os dados do Modal",
        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
        label: "Aula de FitDance Online",
        // number - optional - Numeric value associated with the event. (e.g. A product ID)
        //value: 43,
      });
      
      let endpoint_lambdafunc_sendLeadInfo = process.env.NODE_ENV === "production" ? ".netlify/functions/sendLeadInfo" : process.env.ENDPOINT_SHEET_BEST;

      await fetch(endpoint_lambdafunc_sendLeadInfo, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => response.json()).then(data => {console.debug("formulario enviado com sucesso!"); console.debug(data)})
      .catch(error => console.debug(error));

      let whatsappLink = '';
      if (link === 'whatsapp'){
        whatsappLink = `https://wa.me/5521979188608?text=Oi%20Alexia%2C%20tudo%20bem%3F%20Me%20chamo%20${encodeURIComponent(name)}%2C%20vim%20do%20seu%20site%20e%20quero%20falar%20contigo`;
      }
          
      let newTabOpened = window.open(whatsappLink || link, '_blank');
      if (newTabOpened){
        newTabOpened.focus();
      } else {
        window.location.replace(link);
      }

      closeModal();
    }

    if (invalid){
      errorDiv.classList.add("error");
      errorDiv.style.display = "block";
    }
  }

  return (
    <animated.div style={style} className="modal">
      <div className="modal-dialog">
          <button className="modal-close-button" onClick={closeModalAndRegisterEvents}>
          ×
          </button>
          <h3 className="modal-title">{modalTitle}</h3>
          <p className="modal-content">
              {modalText}
          </p>
          <div className="modal-input">
            <label htmlFor="fNome">Nome: </label><input type="text" name="name" id="fNome" placeholder="digite seu nome..." value={name} onChange={(e) => handleChange(e)} />
            <label htmlFor="fEmail">Email: </label><input type="email" name="email" id="fEmail" placeholder="insira seu melhor email..." value={email} onChange={(e) => handleChange(e)} />
            <label htmlFor="fWhatsApp">WhatsApp: </label><input type="tel" name="whatsapp" id="fWhatsApp" placeholder="(xx) 9xxxx-xxxx" value={whatsapp} onChange={(e) => handleChange(e)} />
          </div>
          <div id="error" sx={{display: `none`}}></div>
          <button className="modal-submit-button" onClick={handleSubmit}>
              {modalCta}
          </button>
      </div>
    </animated.div>
  )
};

export default ModalForm;