/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import { OutboundLink, trackCustomEvent } from "gatsby-plugin-google-analytics";

const Footer = () => {
  let registerEvent = () => {
    trackCustomEvent({
      category: "Link",
      action: "Clicou no link do Desenvolvedor do Site",
      label: "Link Arthur Vieira",
    });
  }
  return (
    <Box as="footer" variant="footer" sx={{ color: 'text' }}>
      Copyright &copy; {new Date().getFullYear()} Alexia Monaco. Developed by
        <OutboundLink
          aria-label="Link to Arthur Vieira's GitHub repository"
          sx={{ ml: 1 }}
          href="https://github.com/arthur-vieira"
          target="_blank"
          onClick={registerEvent}
          eventCategory="Link"
          eventAction="Clicou no link do Desenvolvedor do Site"
          eventLabel="Link Arthur Vieira"
        >
            <span sx={{ color: `#fec10d` }}>Arthur Vieira</span>
        </OutboundLink>
    </Box>
  )
}

export default Footer