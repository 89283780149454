import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Chega junto`}</h2>
    <p>{`Se tiver alguma dúvida, me chama no `}<a href="https://wa.me/5521979188608?text=Oi%20Alexia%2C%20tudo%20bem%3F%20Vim%20do%20seu%20site%20e%20quero%20falar%20contigo" target="_blank" className="footerLinks icon-whatsapp">{`zap`}</a>{`, manda um `}<a href="mailto:alexiamonacoedf@gmail.com" className="footerLinks icon-email">{`email`}</a>{` ou me dá um alô no `}<a href="https://www.instagram.com/alexia.monaco/" target="_blank" className="footerLinks icon-instagram">{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      